import { useSessionStore } from "@/stores/session";
export default function () {
  const sessionStore = useSessionStore();
  const { useIsSameRoute, useIsChangeLang } = useLibs();
  const route = useRoute();
  /**
  * Determine current history:
  * 1.Is the history and current path the same
  * 2.Is the language in the cookie the same as the current language
  **/
  if (
    history.state.back && 
    !useIsSameRoute(history.state.back,route.path) && 
    !useIsChangeLang(route.path)
  ) {
    return history.back();
  }
  let path = "/";
  if (sessionStore.activeProfile?.isKid) {
    path = "/kids";
  }

  return navigateTo({
    path: useLocalePath()(path),
    replace: true,
  });
}
